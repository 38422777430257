<template>
  <div class="navbarmobileFix">
    <section class="banner-two2 img curve  ">
      <div class="container  ">
          <div class=" row  mt-4 d-none d-lg-flex">
            <div class="  col-lg-6 mt-5 pt-5 text-center">
              <h1 class="">Increase the
                Orders You Receive
                From Clinics

              </h1>

              <h1 class=" line-txt">With the RxIx Practice Management App</h1>
            </div>
            <div class="col-lg-6  ">
              <div class="doctorfirstSectionIamge">
                <img class="img-doctor" src="~@/assets/images/hvbanner.png" />
              </div>
            </div>
          </div>
      </div>
    </section>


    <!-- first section only for mobile view -->
    <div class="doctorPageFirstSection">
      <div class="doctor-firstSectionText mt-4 mb-4 text-align text-center container d-block d-lg-none">
        <h1 class="line-txt">Increase the
          Orders <br>You Receive
          From Clinics </h1>
       
   
        <h1 class="mt-3">With the RxIx Order <br> Management App </h1>
        
      </div>
    </div>

    <div class="container mt-5 pt-5 section-space">
      <div class="text-center  ">
        <h1>An ordering app integrated with a clinician's work desk <br> for a frictionless order flow</h1>
      </div>
      <div class="text-center mt-5">
        <h1>Suitable for Healthcare vendors <br> offering custom products to <br> patients through clinicians <br>and to those offering <br> clinic supplies </h1>
       
      </div>

    </div>


   
        <section>
          <div class="container  text-center-cards">
            <h1 class="mb-0">Features at a glance</h1>
            <div class=" features row mt-4 row-cols-1 row-cols-md-3  text-center ">
              
              <div class="col ">
                <div class="card h-100 w-100">
                  <img class="icon-img1" src="~@/assets/images/hv1.png" />

                  <div class="card-body">
                    <h5 class="card-title text-bold">Onboard
                      partner clinics</h5>
                    <p class="card-text">Get your partner clinics onto the app easily. No restriction on the number of
                      clinics or the team size of the clinics.</p>
                  </div>
                </div>
              </div>
              <div class="col ">
                <div class="card h-100 w-100">
                  <img class="icon-img1" src="~@/assets/images/hv2.png" />

                  <div class="card-body">
                    <h5 class="card-title">Publish and manage
                      your products list</h5>
                    <p class="card-text">Maintain a list of products with their custom attributes. Your list is auto
                      published to all your partner clinics and kept current for use in their orders</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 w-100">
                  <img class="icon-img1" src="~@/assets/images/hv3.png" />

                  <div class="card-body">
                    <h5 class="card-title">Practice Management app for partner clinics</h5>
                    <p class="card-text">App is free only for partner clinics of vendors. It aids clinicians in their
                      operations and helps enhance their identity among the patient community.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row features mt-5  row-cols-1 row-cols-md-3 ">

              <div class="col">
                <div class="card h-100 w-100 ">
                  <img class="icon-img1" src="~@/assets/images/hv4.png" />


                  <div class="card-body">
                    <h5 class="card-title">Receive consultation-integrated order</h5>
                    <p class="card-text">With patient's consent, data flows from consultation record, if needed. Also
                      receive patient's measurements and other info to help you deliver your patient-specific product.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 w-100">
                  <img class="icon-img1" src="~@/assets/images/hv5.png" />

                  <div class="card-body">
                    <h5 class="card-title">Track and communicate
                      order progress</h5>
                    <p class="card-text">Gain complete visibility into the order's progress, right from clinician
                      raising an order to delivery of product to patient.</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100 w-100" >
                  <img class="icon-img1" src="~@/assets/images/hv6.png" />

                  <div class="card-body">
                    <h5 class="card-title">Reach out to the
                      RxIx community</h5>
                    <p class="card-text">Enhance awareness of your products. Introduce them to the RxIx community of
                      clinicians and increase the number of your partner clinics.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>

  
    <div class="container rx-wrapper pl-0 pr-0" style="background: #ffffff" id="public">
      <section class="text-color-white section-space">
        <div class="container-fluid doc-text text-center">
          <div class="costForYouText">
            <h1>No capital investment,<br>
              only a transaction processing fee</h1>

          </div>
        </div>
      </section>
      <section class="text-color-white section-space">
        <div class="container-fluid text-align text-center">
          <h1 class="text-center content">Transaction processing fee <br> starts from Rs. 29/- per order</h1>

          <!-- <div class="col-12 mt-3">
              <h3 class="doctor-text cont">
                We charge you a nominal amount per team member.
              </h3>
            </div> -->
        </div>
      </section>
      <div class="container text-align text-center section-space mb-2">
        <div class="connect-text d-none d-lg-block">
          <h1 class="mb-0">Connect with RxIx Help Desk<br>
            to know more about the app<br>
            and to schedule a demo</h1>
        </div>
        <div class="connect-text d-block d-lg-none">
          <h1 class="mb-0">Connect with</h1>
          <h1>RxIx Help Desk</h1>
        </div>
        <div class="container call-whatsapp-content mt-5 mb-3">
          <img class="" width="8%" src="@/assets/images/whatsapp-icon.png">
          <h1 class="whatsapp-number">89 40 89 4300</h1>
        </div>


      </div>

    </div>
  </div>
  <MobileNavbar></MobileNavbar>
</template>
<script>
import MobileNavbar from "../common-mobile-footer.vue"
import { defineComponent } from "vue";
import axios from "axios"
export default defineComponent({
  components: {
    MobileNavbar
  },
  data() {
    return {
      joinData: [],
      practiceGroupCount: '',
      showGrowYourPractice: false,
      showInformedConsultation: false,
    };
  },

  created: function () {
    this.patientsupAdd()
  },
  methods: {
    patientgroupDoctor() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/patientgroups');
    },
    receiveconsultation() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/consultationrequests');
    },
    doctorcommunities() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/communities');
    },
    offerconsultation() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/offerconsultation');
    },
    doctorlibrary() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/clinicalresources');
    },
    informationConsultation() {
      this.showInformedConsultation = true
    },
    yourPractice() {
      this.showGrowYourPractice = true;
    },
    closeYourPractice() {
      this.showGrowYourPractice = false
    },
    closeInformationConsultation() {
      this.showInformedConsultation = false
    },
    async patientsupAdd() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/patient-support/add`)
        .then((response) => {
          if (response?.data) {
            response?.data.map((data) => {
              if (data.status == true) {
                this.joinData.push(data)
                this.practiceGroupCount = this.joinData.length
              }
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
    }

  },
  name: "HomePageB",
  props: {},
});
</script>
<style>
.hideImg {
  z-index: -1;
}

.popupbox {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 6px
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px
}

.bottom-link-navbar {
  display: block !important;
}

li.nav-item.nav {
  display: block !important;
}

.section-space {
  margin-top: 5rem;
}

fieldset.RxixSupportToDoctor legend {
  background: #fff;
  padding: 4px 10px;
  /* color: #000; */
  margin: 0 auto;
  display: block;
  float: none;
  width: auto;
  font-size: 32px;
}

div.connect-text {
  color: #000;
}

.call-whatsapp-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-whatsapp-icon {
  height: 75px;
  width: 75px;
  margin-right: 1rem;
}

span.comming-soon-text {
  font-size: 25px;
  background-color: #cd381c;
  color: #ffff;
  margin-top: 0;
  position: relative;
  bottom: 17px;
  padding: 10px;
  box-shadow: 2px 1px 6px 1px rgba(50, 50, 50, 0.75);
}


.doctorPageFirstSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-group.mt-5.pad {
  width: 100%;
}

.cards-banner-doctor {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #e5f4f5;
  background-image: url("~@/assets/images/bg-top.svg");
  background-repeat: no-repeat;
  background-size: 110%;
  /* padding-top: 130px; */
}


.text-center-cards {
  text-align: center;
  margin-top: 10rem;
  color: #000;
}

.btn-box {
  border-color: #34989f !important;
}


.img-carousel-doctor {
  width: 300px;
  height: 250px;
}.card-body {
  flex: 1 1 auto;
  min-height: 72px;
  padding: 20px;
  background: #fff;
  /* height: 250px; */
}

.nav-tabs {
  border-bottom: 0px !important;
}


.RxixSupportToDoctor {
  display: flex;
  justify-content: space-between;
  border: 2px solid #00979e;
  border-radius: 50px;
  padding: 20px;
}


.heading.text-space {
  margin-bottom: 0px;
  margin-top: 1.5rem;
}


.text-center.content {
  color: #000;
}

.costForYouText h1 {
  color: #000;
}

div.doctorfirstSectionIamge img.img-doctor {
  width: 25vw;
  height: 28vw;
  margin-top: 20px;
}


div.doctor-firstSectionText.mt-4 h1 {
  color: #2c3e50;
  margin-bottom: 0;
  font-size: 2vw;
}

.line-txt {
  margin-top: 3vw;
}



.text-center.mt-5 {
  color: black;
}


section.banner-two2.img.curve {
  background-repeat: no-repeat;
  background-size: cover;

  /* background-image: url("~@/assets/images/better-consultation-banner2.jpg"); */
  background-size: 100% 100%;
  margin-top: 6rem;
}


.rxix-menu li.nav-item a.nav-link {
  color: #00979e;
}

@media screen and (max-width: 990px) {
  hr.border-line {
    width: 65%;
    margin-left: 20%;
    border: 1px solid #00979e;
    margin-bottom: 30px;
  }
}

button.accordion-button.collapsed.text-color.border-color {
  background-color: #48adb1;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #48adb1;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion.box-shadow.mobile-margin {
  width: 70%;
  margin-left: 15%;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .accordion.box-shadow.mobile-margin {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}


.card-body h5{
  font-weight: 600 !important
}
.card-body.card-active {
  margin-top: 2.3rem;
}



.btn.btn-blue-color.text-white.btn-submit {
  float: right;
}

img.icon-img1 {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

/* .card-body {
  flex: 1 1 auto;
  min-height: 72px;
  padding: 20px;
  background: #fff;
  height: 250px;
} */

.join-group.mt-5 {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.h1{
  color: black !important;
}

.card {
  border-radius: 10px;
  cursor: pointer;
  width: 18rem;
  border-color: #a0d4d8;
  border-width: medium;
  position: initial;
  box-shadow:
    8px -8px 12px 0 rgba(0, 0, 0, 0.1),
    -12px 12px 16px rgba(255, 255, 255, 0.25);
}

.card-item {
  background-color: #fff;
  border-radius: 10px;
  width: 18rem;
  border: 2px solid #a0d4d8;
  border-width: medium;
  position: initial;
}

.card-body {
  border-radius: 10px;
}



@media only screen and (max-width: 992px) {
  .carousel-inner {
    width: min-content;
    height: 495px;
    margin: auto;
  }

  .mobileborder {
    border: 2px solid #00979e;
    border-radius: 15px;
    padding: 10px;
    margin-top: 15px;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #00979e;
    opacity: 0.2;
  }

  .carousel-indicators .active {
    opacity: 1;
  }

  .carousel-indicators {
    position: unset;
    border-radius: 50%;
  }

  .img-whatsapp-icon {
    height: 50px;
    width: 50px;
    margin-right: 1rem;
  }

  .line-txt {
    margin-top: 20px;
  }

  .costForYouText h1 {
    font-size: 28px;
  }

  .text-center.content {
    font-size: 28px;
  }

  .div-rxix h1 {
    font-size: 28px;
  }

  .text-center-cards h1 {
    font-size: 28px;
  }

  div.connect-text h1 {
    font-size: 28px;
  }

  .connectwithRxixSubText h3 {
    font-size: 25px;
  }

  h3.doctor-text {
    font-size: 25px;
  }

  fieldset.RxixSupportToDoctor legend {
    font-size: 27px;
  }

  div.doctorfirstSectionIamge img.img-doctor {
    width: 35vw;
    height: 37vw;
  }



  .img-carousel-doctor {
    width: 300px;
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  div.sliderMobileView {
    margin-top: 1rem;
  }

  .join-group.mt-5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  div.col-4.mobileViewCenter {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .questions-icon {
    font-size: 30px;
    color: #fff;
  }

  .whatsapp-number {
    font-size: 28px;
    margin-top: 10px
  }

  .navbarmobileFix {
    margin-bottom: 7rem;
  }

  div.doctor-firstSectionText.mt-4 h1 {
    color: #fff;
    font-size: 30px;
  }

  .doctorPageFirstSection {
    background-color: #00979e;
  }

  section.banner-two2.img.curve {
    background-repeat: no-repeat;
    background-size: cover;

    background-image: url("~@/assets/images/better-consultation-banner2.jpg");
    background-size: 100% 100%;
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {

  .text-center-cards {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 704px) {
  .heading.text-space {
    margin-top: 1rem;
  }

}

@media screen and (max-width:576px) {
  .carousel-inner {
    height: 370px;
  }

  .features .col {
    margin-top: 20px !important;
  }
  
  .questions-icon {
    font-size: 25px;
  }

  .mobileborder {
    margin-top: 10px;
  }

  .popupbox {
    padding: 10px;
  }

  .whatsapp-number {
    font-size: 23px;
  }

  .line-txt {
    margin-top: 15px;
  }

  div.doctor-firstSectionText.mt-4 h1 {
    font-size: 25px;
  }

  .costForYouText h1 {
    font-size: 23px;
  }

  .text-center.content {
    font-size: 23px;
  }

  .div-rxix h1 {
    font-size: 23px;
  }
  .card-body{
    height: 10 !important                                                  ;
  }
  .text-center-cards h1 {
    font-size: 26px;
    font-weight: 600;
  }

  .call-whatsapp-content{
    margin-top: 0 !important;
  }
  .call-whatsapp-content img{
    width: 20% !important;
  }
.section-space{
  margin-top: 26px !important;
}
  div.connect-text h1 {
    font-size: 23px;
  }

  .connectwithRxixSubText h3 {
    font-size: 20px;
  }

  h3.doctor-text {
    font-size: 20px;
  }


  

  fieldset.RxixSupportToDoctor legend {
    font-size: 20px;
  }

}
</style>